import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { HiCheckCircle } from "react-icons/hi"
import mq from "../styles/media-queries"
import SEO from "../components/SEO"
import Hero from "../components/Hero"
import { Section } from "../styles/common/Common"
import {
  AlignCenter,
  Divider,
  IconWrapper,
  Item,
  ItemWrapper,
  H1,
} from "../styles/common/Element"
import ContactForm from "../components/Contact/Contactform"
import BlockContent from "../components/BlockContent"
import ContactBanner from "../components/Banner/ContactBanner"
import { Container, Row } from "../styles/common/Layout"

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  display: flex;
  justify-content: center;
`

const ImgWrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  .gatsby-image-wrapper {
    margin: 0 auto;
    width: 320px;
    ${mq.md} {
      width: 500px;
    }
  }
  h1 {
    color: var(--white);
    text-transform: uppercase;
    filter: drop-shadow(0 0 0.75rem #555555);
  }
  img {
    filter: drop-shadow(0 0 0.75rem #555555);
  }
`

const ItemNoPadding = styled(Item)`
  padding: 0;
`

const ContactFormWrapper = styled.div`
  margin-top: 0px;
  background-color: rgb(255, 255, 255);
  border: none;
  border-radius: 15px;
  padding: 0px;

  text-align: center;

  h1,
  p {
    text-align: center;
  }
  ${mq.md} {
    margin-top: -80px;
    padding: 45px;
    border-width: 4px 0px 0px;
    border-color: var(--mainColor);
    border-style: solid;
    box-shadow: rgba(61, 61, 61, 0.25) 0px 20px 35px 0px;
  }
  .btn {
    ${mq.md} {
      margin: 0 auto;
    }
  }
`

const InfoBoxItem = styled.div`
  width: 100%;
  margin-bottom: 20px;
  background: var(--mainColor);
  border-width: 4px 0px 0px;
  border-color: var(--grey);
  border-style: solid;
  border-radius: 15px;
  padding: 25px;
  transition: border 500ms ease-out;
  z-index: 1;

  ${mq.md} {
    width: 32%;
    margin-bottom: 20px;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      display: flex;
      margin-bottom: 0.5rem;
      color: var(--white);
      svg {
        margin-right: 5px;
        color: var(--white);
      }
    }
  }
`

const H1Box = styled.h1`
  color: var(--white);
  font-size: 1.6rem;
  color: rgb(255, 255, 255);
  display: inline-block;
  font-family: Lato-Regular, sans-serif;
  font-weight: bold;
  line-height: 1.6rem;
  letter-spacing: 1px;
  margin: 0px 0px 0px;
  text-transform: uppercase;
`

const HomePage = ({ location, data }) => {
  return (
    <>
      <SEO title={"Startseite"} />
      <Hero slug={"startseite"} small={false}>
        <Wrapper>
          <ImgWrapper>
            <GatsbyImage
              image={data.settings.image.asset.gatsbyImageData}
              alt="Physiotherapie Dumpe in Raesfeld"
            />
          </ImgWrapper>
        </Wrapper>
      </Hero>
      <Container as="section">
        <Row>
          <ItemWrapper fullWidth>
            <Item>
              <BlockContent blocks={data.page._rawText} />
            </Item>
            <Item>
              <ContactFormWrapper>
                <h1>Termin anfragen!</h1>
                <p>
                  Sie möchten einen Termin vereinbaren oder haben eine Frage?
                  <br /> Dann schreiben Sie uns eine E-Mail!
                </p>
                <ContactForm form="contact" />
              </ContactFormWrapper>
            </Item>
          </ItemWrapper>
        </Row>
      </Container>
      <ContactBanner />
      <Container as="section">
        <Row>
          <ItemWrapper padding={"20px"} justifyContent={"space-between"}>
            <Item padding={"15px 0px 0px"} size={"100%"}>
              <H1 center>THERAPEUTISCHE BEHANDLUNGEN</H1>
              <Divider />
            </Item>
            <InfoBoxItem>
              <H1Box>PHYSIOTHERAPIE</H1Box>
              <ul>
                <li>
                  <HiCheckCircle size={24} /> Krankengymnastik
                </li>
                <li>
                  <HiCheckCircle size={24} /> Manuelle Therapie
                </li>
                <li>
                  <HiCheckCircle size={24} /> Manuelle Lymphdrainage
                </li>
                <li>
                  <HiCheckCircle size={24} /> Behandlungen nach Bobath
                </li>
                <li>
                  <HiCheckCircle size={24} /> Manuelle Lymphdrainage
                </li>
              </ul>
            </InfoBoxItem>
            <InfoBoxItem>
              <H1Box>HILFSTHERAPIEANGEBOTE</H1Box>
              <ul>
                <li>
                  <HiCheckCircle size={24} /> Elektrotherapie
                </li>
                <li>
                  <HiCheckCircle size={24} /> Kältetherapie
                </li>
                <li>
                  <HiCheckCircle size={24} /> Fango/Heißluft
                </li>
                <li>
                  <HiCheckCircle size={24} /> Traktionstherapie
                </li>
              </ul>
            </InfoBoxItem>
            <InfoBoxItem>
              <H1Box>ZUSÄTZLICHEN LEISTUNGEN</H1Box>
              <ul>
                <li>
                  <HiCheckCircle size={24} /> Hausbesuche
                </li>
                <li>
                  <HiCheckCircle size={24} /> Craniosacrale Therapie
                </li>
                <li>
                  <HiCheckCircle size={24} /> Kinesiotape
                </li>
                <li>
                  <HiCheckCircle size={24} /> Atemtherapie
                </li>
                <li>
                  <HiCheckCircle size={24} /> Massage
                </li>
                <li>
                  <HiCheckCircle size={24} /> Kiefergelenksbehandlung/ CMD
                </li>
              </ul>
            </InfoBoxItem>
          </ItemWrapper>
        </Row>
      </Container>
    </>
  )
}

export default HomePage

export const query = graphql`
  query SettingsQuery {
    settings: sanityStoreSettings {
      name
      image {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
    }
    page: sanityPageContent(slug: { current: { eq: "startseite" } }) {
      id
      pageName
      slug {
        current
      }
      subHeadline
      _rawText(resolveReferences: { maxDepth: 10 })
      slider {
        _key
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
      contentImage {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
    }
  }
`
